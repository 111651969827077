import React, { useEffect, useState } from 'react'
import { Link, useLocation, useHistory } from 'react-router-dom'

import AuthenticationLayout from './AuthenticationLayout'
import { submit } from 'redux-form'

const Register = (props) => {
  const { onSubmit, error, submitting } = props
  const history = useHistory()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const [landingPageCohort, setLandingPageCohort] = useState('')

  useEffect(() => {
    const landingPageCohortId = queryParams.get('referrer_page_id')
    landingPageCohortId &&
      setLandingPageCohort(`landing_page_${landingPageCohortId}`)

    history.push({ search: '' })
  }, [])

  return (
    <AuthenticationLayout title="Start your 7-day free trial">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <form
          className="space-y-6"
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit({
              first_name: e.target.first_name.value,
              username: e.target.username.value,
              password: e.target.password.value,
              email: e.target.email.value,
              landing_page_cohort: landingPageCohort,
            })
          }}
        >
          <div>
            <label
              htmlFor="first_name"
              className="block text-sm font-medium text-gray-700"
            >
              First name
            </label>
            <div className="mt-1">
              <input
                id="first_name"
                name="first_name"
                type="text"
                autoComplete="given-name"
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-shade-2 focus:outline-none focus:ring-shade-2 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700"
            >
              Username
            </label>
            <div className="mt-1">
              <input
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-shade-2 focus:outline-none focus:ring-shade-2 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-shade-2 focus:outline-none focus:ring-shade-2 sm:text-sm"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-shade-2 focus:outline-none focus:ring-shade-2 sm:text-sm"
              />
            </div>
          </div>

          <div className="text-red-600">
            {{ error } && <strong>{error}</strong>}
          </div>

          <div>
            <button
              type="submit"
              disabled={submitting}
              className={`flex w-full justify-center rounded-md border border-transparent bg-shade-3 py-2 px-4 text-sm font-medium text-white shadow-sm transition hover:bg-shade-2 focus:outline-none focus:ring-2 focus:ring-shade-2 focus:ring-offset-2 ${
                submitting ? 'cursor-not-allowed opacity-50' : ''
              }}`}
            >
              Register
            </button>
          </div>
        </form>
      </div>

      <div className="mt-8 border-t border-blue-100 pt-6">
        <p>
          Already have an account?{' '}
          <Link className="text-shade-2 hover:underline" to="/login">
            Login
          </Link>
        </p>
      </div>
    </AuthenticationLayout>
  )
}

export default Register
