export default function AuthenticationLayout({ children, title }) {
  return (
    <div>
      <div className="flex min-h-screen flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-16 w-auto"
            src="/img/dendro_logo_icon_only.svg"
            alt="Dendro logo"
          />
          <h2 className="mt-6 text-center text-xl font-bold tracking-tight text-gray-900">
            {title}
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">{children}</div>
      </div>
    </div>
  )
}
