// A list of events to be tracked in Amplitude

// Typos result in creation of new events in Amplitude, so storing exact strings
// here helps to ensure consistency. It is also useful to have all events in one
// place.

// Naming MUST follow the Dendro naming conventions
// For more details, see: https://3.basecamp.com/4354119/buckets/18003200/google_documents/2897219721

export const AMPLITUDE_EVENTS = {
  //In alphabetical order

  ENTER_FEED: 'Enter Feed',
  ORGANIZE_CONTENT: 'Organize Content',
  SHARE_CONTENT: 'Share Content',
  VIEW_PRICING: 'View Pricing',
  VIEW_TUTORIAL: 'View Tutorial',
  GET_NEXT: 'Get Next',
}

export default AMPLITUDE_EVENTS
