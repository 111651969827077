import React, { useState, useEffect } from 'react'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'
import { SWRConfig } from 'swr'
import Snackbar from '@material-ui/core/Snackbar'

import ShowAppOrLogin from '../components/ShowAppOrLogin'
import constructIconLibrary from '../helpers/iconLibrary'
import { fetchProfileData } from '../ducks/profiles'
import { getLiveDevKey } from '../helpers/APIkeys'
import { DENDRO_API_URL } from '..'

const App = () => {
  const token = Cookies.get('key')

  const [userId, setUserId] = useState('')
  const [authToken, setAuthToken] = useState(token)
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(!!token)
  const [newVersionAvailable, setNewVersionAvailable] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    constructIconLibrary()

    setAuthorizationDefaults()
    setAuthTokenFromCookie()
    dispatch(fetchProfileData())

    const startTime = Date.now()

    const versionCheckWorker = new Worker('/js/versionCheckWorker.js')
    versionCheckWorker.postMessage({ action: 'start' })

    versionCheckWorker.onmessage = (e) => {
      if (e.data.action !== 'check') return

      axios.get('/api/latest_update_time/').then((response) => {
        if (!!response.data && new Date(response.data) > startTime) {
          setNewVersionAvailable(true)
          versionCheckWorker.postMessage({ action: 'stop' })
        }
      })
    }

    return () => {
      versionCheckWorker.terminate()
    }
  }, [])

  useEffect(() => {
    //Set global authorization headers for axios using new token
    if (authToken?.length > 0) {
      setAuthorizationDefaults()
      testCredentials()
      dispatch(fetchProfileData())
    }
  }, [authToken])

  useEffect(() => {
    //Poll cookies to log out user on current tab if they
    //log out on another tab
    const cookiePoll = setInterval(() => {
      const cookie = Cookies.get('key')

      if (!cookie && !!isUserLoggedIn) {
        logoutUser()
      }
    }, 5000)

    return () => {
      clearInterval(cookiePoll)
    }
  }, [isUserLoggedIn])

  const logoutUser = () => {
    Cookies.remove('key')
    setUserId('')
    setAuthToken('')
    setIsUserLoggedIn(false)
    clearAuthorizationDefaults()
  }

  const testCredentials = () => {
    axios
      .get('/api/check_login/')
      .then((response) => {
        const { data } = response

        if (data && data.userId) {
          setUserId(data.userId.toString())
          setIsUserLoggedIn(true)
        } else {
          logoutUser()
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          logoutUser()
        }
      })
  }

  const storeAuthTokenAsCookie = (token) => {
    //Persist token in a cookie
    Cookies.set('key', token, { expires: 7 })
    setAuthTokenFromCookie()
  }

  const setAuthTokenFromCookie = () => {
    //Set token in local state
    const cookie = Cookies.get('key')
    if (!!cookie) {
      setAuthToken(cookie)
    } else {
      logoutUser()
    }
  }

  const setAuthorizationDefaults = () => {
    if (authToken?.length > 0) {
      axios.defaults.headers.common['Authorization'] = `Token ${authToken}`
      axios.defaults.withCredentials = true
    }
  }

  const clearAuthorizationDefaults = () => {
    axios.defaults.headers.common['Authorization'] = null
    axios.defaults.withCredentials = false
  }

  const newVersionSnackbarMessage = () => {
    return (
      <div className="text-center">
        There is a new version of Dendro available.{' '}
        <button
          className="my-1 w-full text-base text-blue-400 transition hover:underline"
          onClick={() => {
            window.location.reload()
          }}
        >
          Refresh now
        </button>
      </div>
    )
  }

  return (
    <SWRConfig
      value={{
        fetcher: (url) => {
          if (process.env.NODE_ENV === 'production') {
            url = url.startsWith('/') ? url : `/${url}`
            url = DENDRO_API_URL + url
          }
          return axios.get(url).then((res) => res.data)
        },
      }}
    >
      <Snackbar
        open={newVersionAvailable}
        autoHideDuration={null}
        message={newVersionSnackbarMessage()}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
      <ShowAppOrLogin
        loggedIn={isUserLoggedIn}
        storeAuthToken={storeAuthTokenAsCookie}
        logoutUser={logoutUser}
      />
    </SWRConfig>
  )
}

export default App
