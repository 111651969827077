import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import LearnReducer from '../ducks/learn'
import TagTreeReducer from '../ducks/tagTree'
import LinksReducer from '../ducks/links'
import NotesReducer from '../ducks/notes'
import TreesReducer from '../ducks/trees'
import RootsReducer from '../ducks/roots'
import ProfileReducer from '../ducks/profiles'

const rootReducer = combineReducers({
  learn: LearnReducer,
  tagTree: TagTreeReducer,
  notes: NotesReducer,
  links: LinksReducer,
  tree: TreesReducer,
  form: formReducer,
  roots: RootsReducer,
  profile: ProfileReducer,
})

export default rootReducer
