import React from 'react'

import GutteredDiv from './GutteredDiv'
import AuthenticationLayout from './AuthenticationLayout'

const RegistrationConfirmation = (props) => {
  const content = (
    <AuthenticationLayout title="Almost done!">
      <p className="mb-4 text-center">
        You'll receive an email in the next minute or so, with a link to confirm
        your address.
      </p>
      <p className="mb-4 text-center">
        Please check your <b>junk/spam</b> if you can't find it.
      </p>
    </AuthenticationLayout>
  )

  return <GutteredDiv content={content} />
}

export default RegistrationConfirmation
