import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import App from './containers/App'
import promise from 'redux-promise'
import reducers from './reducers'
import { unregister } from './registerServiceWorker'
import { Route, BrowserRouter } from 'react-router-dom'
import axios from 'axios'
import createSagaMiddleware from 'redux-saga'

import './build.css'
import rootSaga from './ducks/sagas'

//set axios global defaults
//TODO: check if the csrftoken is available, otherwise get it
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

export const DENDRO_API_URL = 'https://api.dendro.cloud'

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = DENDRO_API_URL
}

const sagaMiddleware = createSagaMiddleware()

const createStoreWithMiddleware = applyMiddleware(
  promise,
  sagaMiddleware
)(createStore)

ReactDOM.render(
  <Provider store={createStoreWithMiddleware(reducers)}>
    <BrowserRouter>
      <Route component={App} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
)

sagaMiddleware.run(rootSaga)

unregister()
