import React from 'react'

const GutteredDiv = ({ content, leftContent, rightContent }) => {
  return (
    <div className="flex flex-wrap">
      <div className="hidden flex-1 grow px-4 sm:block">{leftContent}</div>
      <div className="w-full max-w-6xl px-1 sm:px-4">{content}</div>
      <div className="hidden flex-1 grow px-4 sm:block">
        {rightContent}
      </div>
    </div>
  )
}

export default GutteredDiv
