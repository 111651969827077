import React, { useEffect, useState, Fragment } from 'react'
import axios from 'axios'
import { Link, withRouter } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { Menu, Transition } from '@headlessui/react'

import EVENTS from '../amplitude-events'
import GutteredDiv from './GutteredDiv'
import CurrentSelectiveEngageButton from './CurrentSelectiveEngageButton'
import { logEvent } from '../helpers/eventTracking'

const Navbar = ({ history, loggedIn }) => {
  const homeButton = (
    <button className="px-3 py-2" onClick={() => history.push('/home')}>
      <FontAwesomeIcon icon="home" /> Home
    </button>
  )

  const addNoteButton = (
    <a className="px-3 py-2" href="/note/new">
      <FontAwesomeIcon icon="file-alt" /> Add note
    </a>
  )

  const tutorialUrl = 'https://help.dendro.cloud/category/4-getting-started'

  const tutorialButton = (
    <button
      className="block w-full py-1 px-3"
      onClick={() => {
        logEvent(EVENTS.VIEW_TUTORIAL)
        window.open(tutorialUrl, '_blank')
        return true
      }}
    >
      <FontAwesomeIcon icon="graduation-cap" /> Learn
    </button>
  )

  const searchButton = (
    <button
      className="px-3 py-2"
      onClick={() => {
        history.push('/search')
      }}
    >
      <FontAwesomeIcon icon="search" /> Search
    </button>
  )

  const [supportDialogOpen, toggleSupportDialog] = useState(false)

  const supportButton = (
    <button
      className="block w-full py-1 px-3"
      onClick={() => toggleSupportDialog(true)}
    >
      <FontAwesomeIcon icon="question-circle" /> Support
    </button>
  )

  const [isPayingCustomer, setIsPayingCustomer] = useState(false)

  useEffect(() => {
    if (!!loggedIn) {
      axios.get('/api/get-customer').then((response) => {
        if (!!response.data.customer_id) {
          setIsPayingCustomer(true)
        }
      })
    }
  }, [])

  const getStripePortal = () => {
    axios
      .post('/api/stripe-portal/')
      .then((response) => {
        window.location.href = response.data
      })
      .catch(() => {
        setLoadingStripePortal(false)
        alert('Unable to reach server. Please check your internet connection.')
      })
  }

  const billingButton = (
    <button
      className="block w-full py-1 px-3"
      type="submit"
      onClick={getStripePortal}
    >
      <FontAwesomeIcon icon="credit-card" /> &nbsp; Billing
    </button>
  )

  const signoutButton = (
    <Link
      to="/logout"
      className="block w-full py-1.5 px-3 text-gray-900 hover:bg-gray-600 hover:text-white"
    >
      Sign Out
    </Link>
  )

  const renderToggler = () => {
    if (!loggedIn) {
      /*hide navbar if user is not logged in*/
      return null
    } else {
      /*Display based on screen size*/
      return (
        <div className="flex flex-row items-center">
          <div className="hidden py-1 px-3 sm:block">{homeButton}</div>

          <div className="hidden py-1 px-3 sm:block">{addNoteButton}</div>

          <div className="hidden py-1 px-3 sm:block">{searchButton}</div>

          <Menu as="div" className="relative inline-block">
            {({ open }) => (
              <>
                <div>
                  <Menu.Button className="ml-2 py-1.5 px-3 text-2xl">
                    <FontAwesomeIcon icon="bars" />
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="focus:outline-none absolute right-0 z-50 mt-1 w-52 origin-top-right rounded-md bg-white text-center shadow-lg ring-1 ring-black ring-opacity-5"
                  >
                    <div className="py-1">
                      <Menu.Item className="block w-full py-1.5 px-3 text-gray-900 hover:bg-gray-600 hover:text-white sm:hidden">
                        {homeButton}
                      </Menu.Item>

                      <Menu.Item className="block w-full py-1.5 px-3 text-gray-900 hover:bg-gray-600 hover:text-white sm:hidden">
                        {searchButton}
                      </Menu.Item>

                      <Menu.Item className="w-full py-1.5 px-3 text-gray-900 hover:bg-gray-600 hover:text-white">
                        {tutorialButton}
                      </Menu.Item>

                      <Menu.Item className="w-full py-1.5 px-3 text-gray-900 hover:bg-gray-600 hover:text-white">
                        {supportButton}
                      </Menu.Item>

                      <Menu.Item
                        className={`w-full py-1.5 px-3 text-gray-900 hover:bg-gray-600 hover:text-white ${
                          !!isPayingCustomer ? '' : 'hidden'
                        }`}
                      >
                        {billingButton}
                      </Menu.Item>

                      <Menu.Item as="div" className="border-t">
                        {signoutButton}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      )
    }
  }

  const dendroBrand = (
    <Link className="mb-0 mr-4 pl-0 pt-1 pb-1" to="/home">
      {/*sm and larger*/}
      <img
        src={'/img/dendro_logo.svg'}
        alt="Dendro logo"
        className="hidden h-auto max-w-full sm:inline-block"
        style={{ width: 180 }}
      />

      {/*For xs*/}
      <img
        src={'/img/dendro_logo.svg'}
        alt="Dendro logo"
        className="inline-block h-auto max-w-full sm:hidden"
        style={{ width: 150 }}
      />
    </Link>
  )

  const closeSupportDialog = () => toggleSupportDialog(false)

  const renderSupportDialog = (
    <Dialog open={supportDialogOpen} onClose={closeSupportDialog}>
      <DialogContent>
        <h3 className="mb-2 text-center text-3xl">Support</h3>
        <p className="mb-4">
          For a general overview of how to learn with Dendro, see our{' '}
          <a
            className="text-link-blue hover:underline"
            href={tutorialUrl}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              logEvent(EVENTS.VIEW_TUTORIAL)
              return true
            }}
          >
            tutorial
          </a>
          .
        </p>
        <p className="mb-4">
          For more specific questions or feedback, you can email us at{' '}
          <a
            className="text-link-blue hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="mailto:hello@dendro.cloud"
          >
            hello@dendro.cloud
          </a>
          . You should hear back within <b>48 hours</b>. If you don't, please
          check your spam inbox.
        </p>
      </DialogContent>
    </Dialog>
  )

  const content = (
    <div style={{ boxShadow: '0 3px 2px -2px #e0e0e0' }} className="bg-white">
      <GutteredDiv
        content={
          <nav
            className="flex flex-nowrap items-center justify-between bg-white pt-3 pb-2 pl-2"
            id="dendro-navbar-top"
          >
            <div className="flex items-center">
              {dendroBrand}
              {!!loggedIn && <CurrentSelectiveEngageButton />}
            </div>
            {renderToggler()}
            {renderSupportDialog}
          </nav>
        }
      />
    </div>
  )

  return content
}

export default withRouter(Navbar)
