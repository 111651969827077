import axios from 'axios'

import { DELETE } from './notes'

//Action Types
const FETCH_TREE = 'frontend/trees/FETCH_TREE'

//Reducer
export default function reducer(state = [], action) {
  switch (action.type) {
    case FETCH_TREE:
    case DELETE:
      if (!!action.payload.data) {
        return JSON.parse(action.payload.data)
      } else {
        //source note was deleted
        return []
      }
    default:
      return state
  }
}

//Action Creators
export function fetchTree(id) {
  const request = axios.get(`/api/content/${id}/expand/`)
  return { type: FETCH_TREE, payload: request }
}
