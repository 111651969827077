import React, { Suspense, lazy } from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'

//Static imports
//These components must be ready on initial load of app
import Navbar from '../components/Navbar'
import LoginContainer from '../containers/LoginContainer'
import RegisterContainer from '../containers/RegisterContainer'
import RegistrationConfirmation from '../components/RegistrationConfirmation'
import GutteredDiv from '../components/GutteredDiv'
import Logout from '../components/Logout'
import ForgotPassword from '../components/ForgotPassword'
import ForgotPasswordConfirmation from '../components/ForgotPasswordConfirmation'

//Lazy imports
const ActivityContainer = lazy(() => import('../containers/ActivityContainer'))
const AppBilling = lazy(() => import('../components/AppBilling'))
const LearnContainer = lazy(() => import('../containers/LearnContainer'))
const LinkContainer = lazy(() => import('../containers/LinkContainer'))
const NoteContainer = lazy(() => import('../containers/NoteContainer'))
const LightbulbJournal = lazy(() => import('../components/LightbulbJournal'))
const NoteTreePage = lazy(() => import('./NoteTreePage'))
const PDFImportContainer = lazy(() =>
  import('../containers/PDFImportContainer')
)
const StartPage = lazy(() => import('../components/StartPage'))
const Search = lazy(() => import('./Search'))
const SourceList = lazy(() => import('./SourceList'))
const CompletedExportsList = lazy(() => import('./Export/CompletedExportsList'))
const Export = lazy(() => import('./Export/Export'))
const CoachingConfirmation = lazy(() =>
  import('../components/CoachingConfirmation')
)
const SubscriptionConfirmation = lazy(() =>
  import('../components/SubscriptionConfirmation')
)
const TagContainer = lazy(() => import('../containers/TagContainer'))
const TagTreeContainer = lazy(() => import('../containers/TagTreeContainer'))
const NotFound = lazy(() => import('../components/NotFound'))
const WebImport = lazy(() => import('../containers/WebImport'))
const CoachingBilling = lazy(() => import('./CoachingBilling'))

const ShowAppOrLogin = ({ loggedIn, logoutUser, storeAuthToken }) => {
  if (loggedIn === null) {
    return <GutteredDiv content={<h3>Loading...</h3>} />
  }

  const loggedInRoutes = (
    <Switch>
      <Route exact path="/activity" component={ActivityContainer} />
      <Route exact path="/sources" component={SourceList} />
      <Route exact path="/lightbulb-journal" component={LightbulbJournal} />
      <Route
        exact
        path="/completed-projects"
        component={CompletedExportsList}
      />
      <Route exact path="/project/:id" component={Export} />
      <Route path="/note/new/" component={NoteContainer} />
      <Route exact path="/note/:id/expand/" component={NoteTreePage} />
      <Route exact path="/note/:id" component={NoteContainer} />
      <Route path="/interest/:id" component={TagContainer} />
      <Route exact path="/interests" component={TagTreeContainer} />
      <Route path="/learn" component={LearnContainer} />
      <Route exact path="/task/:id/expand/" component={NoteTreePage} />
      <Route path="/task/:id" component={LinkContainer} />
      <Route exact path="/home" component={StartPage} />
      <Route exact path="/" render={() => <Redirect to="/home" />} />
      <Route exact path="/login" render={() => <Redirect to="/home" />} />
      <Route
        exact
        path="/logout"
        render={() => <Logout logoutUser={logoutUser} />}
      />
      <Route exact path="/register" render={() => <Redirect to="/home" />} />
      <Route exact path="/web-import" component={WebImport} />
      <Route exact path="/upgrade-subscription" component={AppBilling} />
      <Route exact path="/get-coaching" component={CoachingBilling} />
      <Route exact path="/pdf-import" component={PDFImportContainer} />
      <Route exact path="/search" component={Search} />
      <Route
        exact
        path="/subscription-success"
        component={SubscriptionConfirmation}
      />
      <Route exact path="/coaching-success" component={CoachingConfirmation} />
      <Route component={NotFound} />
    </Switch>
  )

  const loggedOutRoutes = (
    <Switch>
      <Route
        exact
        path="/login"
        render={() => <LoginContainer storeAuthToken={storeAuthToken} />}
      />
      <Route
        exact
        path="/register"
        render={() => <RegisterContainer storeAuthToken={storeAuthToken} />}
      />
      <Route exact path="/home" render={() => <Redirect to="/login" />} />
      <Route exact path="/forgot-password" component={ForgotPassword} />
      <Route
        exact
        path="/forgot-password-confirmation"
        component={ForgotPasswordConfirmation}
      />
      <Route
        exact
        path="/confirm-registration"
        component={RegistrationConfirmation}
      />
      <Route render={() => <Redirect to="/login" />} />
    </Switch>
  )

  if (!loggedIn) {
    return <div>{loggedOutRoutes}</div>
  }

  return (
    <div>
      <Navbar loggedIn={loggedIn} />
      <div className="main-content">
        <Suspense fallback={<GutteredDiv content={<div>Loading...</div>} />}>
          {loggedInRoutes}
        </Suspense>
      </div>
    </div>
  )
}

ShowAppOrLogin.propTypes = {
  loggedIn: PropTypes.bool,
}

export default ShowAppOrLogin
