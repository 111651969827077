import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faArrowRight,
  faBan,
  faBars,
  faBookmark,
  faCheck,
  faCog,
  faCreditCard,
  faEdit,
  faEllipsisV,
  faExternalLinkAlt,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFilePdf,
  faGlobe,
  faGraduationCap,
  faHome,
  faLock,
  faMinus,
  faPlus,
  faPlusCircle,
  faQuestionCircle,
  faSearch,
  faTrashAlt,
  faLightbulb,
  faWindowClose,
  faFrown,
  faMeh,
  faSmile,
  faGrinHearts,
} from '@fortawesome/free-solid-svg-icons'

export default function constructIconLibrary() {
  //Prepare fontawesome font library
  //https://github.com/FortAwesome/react-fontawesome
  library.add(
    faArrowRight,
    faBars,
    faBan,
    faBookmark,
    faCheck,
    faCog,
    faEllipsisV,
    faExternalLinkAlt,
    faChevronDown,
    faChevronRight,
    faChevronUp,
    faCreditCard,
    faEdit,
    faEye,
    faEyeSlash,
    faFileAlt,
    faFilePdf,
    faGlobe,
    faGraduationCap,
    faHome,
    faLock,
    faMinus,
    faPlus,
    faPlusCircle,
    faQuestionCircle,
    faSearch,
    faTrashAlt,
    faLightbulb,
    faWindowClose,
    faFrown,
    faMeh,
    faSmile,
    faGrinHearts
  )
}

//Use like this:
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//<FontAwesomeIcon icon="plus-circle" color="#639163" size="2x"/>
