import axios from 'axios'

//Action Types
const FETCH_LINK = 'frontend/links/FETCH_LINK'
const GRADE_LINK = 'frontend/links/GRADE_LINK'
const UPDATE = 'frontend/links/UPDATE'

//Helpers

//Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case UPDATE:
    case FETCH_LINK:
      if (!!action.payload.data) {
        const existingData = state[action.payload.data.id]

        const new_state = {
          ...state,
          [action.payload.data.id]: {
            ...existingData,
            ...action.payload.data,
          },
        }
        return new_state
      } else {
        return state
      }
    default:
      return state
  }
}

//Action Creators
export function fetchLink(id) {
  const request = axios.get(`/api/tasks/${id}/`)
  return { type: FETCH_LINK, payload: request }
}

export function gradeLink(values, callback) {
  const request = axios
    .post(`/api/tasks/${values.id}/grade/`, values)
    .then((res) => callback(res.data))
  return { type: GRADE_LINK, payload: request }
}

export function updateLink(values, optimistic = false) {
  if (optimistic) {
    // since we need to be able to roll back the values in case the request fails, we have to handle
    // the request in the same place that we call the action creator, so we'll bypass it here
    return { type: UPDATE, payload: { data: values } }
  }

  const request = axios.patch(`/api/tasks/${values.id}/`, values)
  return {
    type: UPDATE,
    payload: request,
  }
}

//Selectors

//Sagas
