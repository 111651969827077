import axios from 'axios'

//Action Types
const FETCH_PROFILE = 'frontend/profile/FETCH_PROFILE'
const UPDATE_PROFILE = 'frontend/profile/UPDATE_PROFILE'

//Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case UPDATE_PROFILE:
    case FETCH_PROFILE:
      if (!action.error) {
        return action.payload.data
      } else {
        return state
      }
    default:
      return state
  }
}

//Action Creators

//  Note: id=1 is a required dummy variable

export function fetchProfileData() {
  const request = axios.get('/api/user_profile/1/')
  return { type: FETCH_PROFILE, payload: request }
}

export function updateProfileData(values) {
  const request = axios.patch('/api/user_profile/1/', values)
  return { type: UPDATE_PROFILE, payload: request }
}
