import axios from 'axios'

//Action Types
const FETCH_NOTE = 'frontend/notes/FETCH_NOTE'
export const CLEAR_ALL = 'frontend/notes/CLEAR_ALL'
const CREATE = 'frontend/notes/CREATE'
export const DELETE = 'frontend/notes/DELETE'
const UPDATE = 'frontend/notes/UPDATE'
const UPDATE_LIGHTBULB = 'frontend/notes/UPDATE_LIGHTBULB'
const MAKE_EXTRACT = 'frontend/notes/MAKE_EXTRACT'

//Helpers

//Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case CLEAR_ALL:
      return {}
    case UPDATE:
    case FETCH_NOTE:
      if (!!action.payload.data) {
        const existingData = state[action.payload.data.id]

        return {
          ...state,
          [action.payload.data.id]: {
            ...existingData,
            ...action.payload.data,
          },
        }
      } else {
        return state
      }
    case UPDATE_LIGHTBULB:
      if (!!action.payload.data) {
        //Only update lightbulbed_at for given id
        const { data } = action.payload
        const newState = { ...state }
        newState[data.id] = {
          ...newState[data.id],
          lightbulbed_at: data.lightbulbed_at,
        }
        return newState
      } else {
        return state
      }
    default:
      return state
  }
}

//Action Creators
export function fetchNote(id) {
  const request = axios.get(`/api/notes/${id}/`)
  return { type: FETCH_NOTE, payload: request }
}

export function createNote(values, callback) {
  const request = axios
    .post('/api/notes/', values)
    .then((response) => callback(response, null))
    .catch((error) => callback(null, error))
  return { type: CREATE, payload: request } //add to store?
}

export function addLightbulb(id) {
  const request = axios.post('/api/lightbulbs/', { id })
  return { type: UPDATE_LIGHTBULB, payload: request }
}

export function removeLightbulb(id) {
  const request = axios.delete(`/api/lightbulbs/${id}`)
  return { type: UPDATE_LIGHTBULB, payload: request }
}

export function updateNote(values, optimistic = false) {
  if (optimistic) {
    // since we need to be able to roll back the values in case the request fails, we have to handle
    // the request in the same place that we call the action creator, so we'll bypass it here
    return { type: UPDATE, payload: { data: values } }
  }

  const request = axios.patch(`/api/notes/${values.id}/`, values)
  return {
    type: UPDATE,
    payload: request,
  }
}

export function updateRefs(values) {
  const request = axios.patch(`/api/content/${values.id}/updaterefs/`, values)
  return { type: UPDATE, payload: request }
}

export function deleteNote(id) {
  const request = axios.delete(`/api/content/${id}/`)
  return { type: DELETE, payload: request }
}

export function makeExtract(values, callback) {
  const request = axios
    .post(`/api/notes/${values.id}/extract/`, values)
    .then((response) => callback(response))
  return { type: MAKE_EXTRACT, payload: request }
}

//Selectors

//Sagas
export const noteSagas = []
