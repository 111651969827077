import React from 'react'
import { Link } from 'react-router-dom'
import AuthenticationLayout from './AuthenticationLayout'

const ForgotPasswordConfirmation = () => {
  return (
    <AuthenticationLayout title="Forgot password">
      <p>
        An email has been sent to your account with a link to reset your
        password.
      </p>
      <p className="mt-4">
        Return to the{' '}
        <Link to="/login" className="text-link-blue hover:underline">
          home page
        </Link>
        .
      </p>
    </AuthenticationLayout>
  )
}

export default ForgotPasswordConfirmation
