import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'

import DendroDialog from './DendroDialog'
import Spinner from './Spinner'
import { getNextNote } from '../ducks/learn'

const CurrentSelectiveEngageButton = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [tags, setTags] = useState([])
  const [searchTerms, setSearchTerms] = useState('')
  const [question, setQuestion] = useState('')
  const [loading, setLoading] = useState(false)
  const feedContent = useSelector((state) => state.learn)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!!feedContent && !!feedContent.filter) {
      if (!!feedContent.filter.tags && feedContent.filter.tags.length > 0) {
        setTags(feedContent.filter.tags)
      } else if (!!feedContent.filter.search_terms) {
        setSearchTerms(feedContent.filter.search_terms)
      } else if (!!feedContent.filter.question) {
        setQuestion(feedContent.filter.question)
      }
    }

    if (!!feedContent && !feedContent.filter) {
      setTags([])
      setSearchTerms('')
      setQuestion('')
    }

    if (!feedContent) {
      dispatch(getNextNote())
    }
  }, [dispatch, feedContent, setTags])

  const tagsList = tags.map((tag) => <li key={tag}>{tag}</li>)

  if (!tags.length && !searchTerms && !question) {
    return null
  }

  return (
    <>
      <button
        type="button"
        className="select-none rounded border border-amber-600 bg-white py-1.5 px-1.5 align-middle text-amber-600 transition hover:bg-amber-600 hover:text-white"
        onClick={() => setModalOpen(true)}
      >
        Focus feed
      </button>
      <DendroDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        maxWidth="sm"
        fullWidth={true}
        fullScreenAt="xs"
        isDraggable={true}
        draggableTitle="You are in a focused feed"
      >
        <DialogContent>
          {!!tags.length && (
            <>
              <p className="mb-4">Tag{tags.length > 1 && 's'}:</p>
              <ul className="mb-4 ml-10 list-disc">{tagsList}</ul>
            </>
          )}

          {!!searchTerms && (
            <>
              <p className="mb-4">Search Terms:</p>
              <ul className="mb-4 ml-10 list-disc">
                <li>{searchTerms}</li>
              </ul>
            </>
          )}

          {!!question && (
            <>
              <p className="mb-4">Question:</p>
              <ul className="mb-4 ml-10 list-disc">
                <li>{question}</li>
              </ul>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <button
            className="select-none rounded border bg-amber-600 py-1.5 px-3 text-white hover:bg-amber-700 disabled:opacity-70"
            disabled={loading}
            onClick={() => setModalOpen(false)}
          >
            Continue
          </button>

          <button
            className="select-none rounded border bg-gray-600 py-1.5 px-3 text-white hover:bg-gray-700 disabled:opacity-70"
            disabled={loading}
            onClick={() => {
              setLoading(true)
              axios.delete('/api/queuefilter/').then(() => {
                setLoading(false)
                dispatch(getNextNote())
                setModalOpen(false)
              })
            }}
          >
            Return to main feed
          </button>
          <Spinner enabled={loading} colorClass="text-gray-600" />
        </DialogActions>
      </DendroDialog>
    </>
  )
}

export default CurrentSelectiveEngageButton
