import React, { useState } from 'react'
import axios from 'axios'
import { Redirect } from 'react-router-dom'
import { createWidget } from '@typeform/embed'
import '@typeform/embed/build/css/widget.css'

import Register from '../components/Register'

const RegisterContainer = () => {
  const [success, setSuccess] = useState(false)
  const [questionnaireSubmitted, setQuestionnaireSubmitted] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [submitting, setSubmitting] = useState(false)
  const [surveyEnabled] = useState(false) // hardcoded to hide survey

  const onSubmit = (values) => {
    values.password1 = values.password
    values.password2 = values.password

    setSubmitting(true)

    return axios
      .post('/rest-auth/registration/', values)
      .then((res) => {
        createWidget('JARMFjGk', {
          container: document.querySelector('#typeform'),
          onSubmit: (event) => {
            setQuestionnaireSubmitted(true)
          },
          hidden: {
            user_id: res.data.user,
            landing_page_cohort: res.data.landing_page_cohort,
          },
        })

        setSuccess(true)
      })
      .catch((error) => {
        if (!!error.response && error.response.status === 400) {
          //Handle server validation errors
          const { username, password1, email } = error.response.data
          if (!!username) {
            setErrorMessage(username)
          }
          if (!!password1) {
            setErrorMessage(password1)
          }
          if (!!email) {
            setErrorMessage(email)
          }
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  // Redirect to confirmation page if the user has successfully registered
  // If survey is enabled, wait till it's submitted before redirecting
  if (success && (!surveyEnabled || questionnaireSubmitted)) {
    return <Redirect to="/confirm-registration" />
  }

  return (
    <>
      <div
        id="typeform"
        className={`w-screen ${
          !!success && !!surveyEnabled ? 'block' : 'hidden'
        }`}
        style={{
          height: '100vh',
          width: '100vw',
        }}
      ></div>
      <div className={`${!!success ? 'hidden' : 'block'}`}>
        <Register
          submitting={submitting}
          error={errorMessage}
          onSubmit={onSubmit}
        />
      </div>
    </>
  )
}

export default RegisterContainer
