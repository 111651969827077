import { useEffect } from 'react'
import axios from 'axios'

const Logout = ({ logoutUser }) => {
  useEffect(() => {
    axios.post('/rest-auth/logout/').finally(() => {
      logoutUser()
    })
  }, [logoutUser])

  return null
}

export default Logout
