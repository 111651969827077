import axios from 'axios'
import { put, call, takeLatest } from 'redux-saga/effects'

//Action Types
const FETCH_NEXT = 'frontend/learn/FETCH_NEXT'
const UPDATE = 'frontend/learn/UPDATE'

//Reducer
export default function (state = null, action) {
  switch (action.type) {
    case FETCH_NEXT:
      if (action.payload.data) {
        return action.payload.data
      } else {
        return state
      }
    case UPDATE:
      return {} //clear state
    default:
      return state
  }
}

//Action Creators
export function fetchNextNote() {
  return axios.get('/api/learn/next/')
}

export function getNextNote() {
  return { type: UPDATE }
}

//Sagas
export const learnSagas = [takeLatest(UPDATE, updateLearnQueue)]

function* updateLearnQueue(action) {
  const request = yield call(fetchNextNote)
  yield put({ type: FETCH_NEXT, payload: request })
}
