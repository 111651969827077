import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import AuthenticationLayout from './AuthenticationLayout'

const ForgotPassword = (props) => {
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  return (
    <AuthenticationLayout title="Forgot password">
      <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <p className="mb-4">
          If you don't remember your password, please enter the{' '}
          <strong>email</strong> address you used to sign up:
        </p>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            setSubmitting(true)
            setErrorMessage('')
            axios
              .post('/rest-auth/password/reset/', {
                email: e.target.email.value,
              })
              .then((res) => {
                setSubmitting(false)
                props.history.push('/forgot-password-confirmation')
              })
              .catch((error) => {
                if (
                  error.response &&
                  error.response.status === 400 &&
                  error.response.data?.email?.email
                ) {
                  setErrorMessage(error.response.data?.email?.email)
                }
              })
              .finally(() => {
                setSubmitting(false)
              })
          }}
          className="space-y-6"
        >
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <div className="mt-1">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="focus:outline-none block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-shade-2 focus:ring-shade-2 sm:text-sm"
              />
            </div>
          </div>

          <div className="text-red-600">
            {{ errorMessage } && <strong>{errorMessage}</strong>}
          </div>

          <button
            type="submit"
            disabled={submitting}
            className="focus:outline-none flex w-full justify-center rounded-md border border-transparent bg-shade-3 py-2 px-4 text-sm font-medium text-white shadow-sm transition hover:bg-shade-2 focus:ring-2 focus:ring-shade-2 focus:ring-offset-2"
          >
            Send reset link
          </button>
        </form>
      </div>

      <div className="mt-8 border-t border-blue-100 pt-6">
        <p>
          Don't need to reset your password?{' '}
          <Link className="text-shade-2 hover:underline" to="/login">
            Click here
          </Link>{' '}
          to login.
        </p>
      </div>
    </AuthenticationLayout>
  )
}

export default ForgotPassword
