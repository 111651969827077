import React from 'react'
import { Link, useLocation } from 'react-router-dom'

import AuthenticationLayout from './AuthenticationLayout'

const Login = (props) => {
  const { onSubmit, submitting, errorMessage } = props
  const searchParams = new URLSearchParams(useLocation().search)
  const emailConfirmed = searchParams.get('emailConfirmed')

  const emailConfirmedBanner = (
    <div className="bg-shade-4 px-6 py-2.5 sm:px-3.5">
      <p className="text-center leading-6 text-shade-2">
        <strong className="font-semibold">
          Your email address is confirmed! 🎉
        </strong>
      </p>
    </div>
  )

  return (
    <>
      {emailConfirmed && emailConfirmedBanner}
      <AuthenticationLayout title="Sign in to your account">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form
            className="space-y-6"
            onSubmit={(e) => {
              e.preventDefault()
              onSubmit({
                username: e.target.username.value,
                password: e.target.password.value,
              })
            }}
          >
            <div>
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700"
              >
                Username
              </label>
              <div className="mt-1">
                <input
                  id="username"
                  name="username"
                  type="text"
                  autoComplete="username"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-shade-2 focus:outline-none focus:ring-shade-2 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-shade-2 focus:outline-none focus:ring-shade-2 sm:text-sm"
                />
              </div>

              <div className="mt-2 flex justify-end text-sm">
                <Link
                  to="/forgot-password"
                  className="font-medium text-shade-2 transition hover:text-shade-3"
                >
                  Forgot your password?
                </Link>
              </div>
            </div>

            <div className="text-red-600">
              {{ errorMessage } && <strong>{errorMessage}</strong>}
            </div>

            <div>
              <button
                type="submit"
                disabled={submitting}
                className="flex w-full justify-center rounded-md border border-transparent bg-shade-3 py-2 px-4 text-sm font-medium text-white shadow-sm transition hover:bg-shade-2 focus:outline-none focus:ring-2 focus:ring-shade-2 focus:ring-offset-2"
              >
                Login
              </button>
            </div>
          </form>
        </div>

        <div className="mt-8 border-t border-blue-100 pt-6">
          <p>
            If you don't have an account but want to try Dendro,{' '}
            <Link className="text-shade-2 hover:underline" to="/register">
              click here.
            </Link>
          </p>
        </div>
      </AuthenticationLayout>
    </>
  )
}

export default Login
