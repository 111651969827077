import axios from 'axios'
import { createSelector } from 'reselect'

import { convertTagTreeToList } from '../helpers/tags'

//Action Types
const CREATE = 'frontend/tagTree/CREATE'
const FETCH_ALL = 'frontend/tagTree/FETCH_ALL'
const MODIFY_TREE = 'frontend/tagTree/MODIFY_TREE'

//Reducer
export default function reducer(state = { items: {}, rootId: '' }, action) {
  switch (action.type) {
    case CREATE:
    case FETCH_ALL:
      return JSON.parse(action.payload.data)
    case MODIFY_TREE:
      return action.payload
    default:
      return state
  }
}

//Action Creators
export function fetchTagTree() {
  const request = axios.get('/api/treetags/')
  return { type: FETCH_ALL, payload: request }
}

export function modifyTree(newTree) {
  return { type: MODIFY_TREE, payload: newTree }
}

export function createTag(res) {
  return { type: CREATE, payload: res }
}

//Selectors
const getTagTree = (state) => state.tagTree

//Useful for providing tag suggestions
export const getOrderedTagList = createSelector([getTagTree], (tagTree) =>
  convertTagTreeToList(tagTree)
)
