import mapKeys from 'lodash/mapKeys'
import axios from 'axios'

//Action Types
const FETCH_ALL = 'frontend/roots/FETCH_ALL' //selector? (use depth=1)

//Reducer
export default function reducer(state = {}, action) {
  switch (action.type) {
    case FETCH_ALL:
      return mapKeys(action.payload.data, 'id')
    default:
      return state
  }
}

//Action Creators
export function fetchRoots() {
  const request = axios.get('/api/content/roots/')
  return { type: FETCH_ALL, payload: request }
}
