import React, { useState } from 'react'
import axios from 'axios'

import Login from '../components/Login'

export default function LoginContainer({ storeAuthToken }) {
  const [submitting, setSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onSubmit = (values) => {
    setSubmitting(true)

    return axios
      .post('/rest-auth/login/', values)
      .then((res) => {
        storeAuthToken(res.data.key)
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setErrorMessage('Invalid username or password')
        }
      })
      .finally(() => {
        setSubmitting(false)
      })
  }

  return (
    <Login
      onSubmit={onSubmit}
      submitting={submitting}
      errorMessage={errorMessage}
    />
  )
}
