import React from 'react'
import PropTypes from 'prop-types'
import Draggable from 'react-draggable'
import Paper from '@material-ui/core/Paper'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import styled from 'styled-components'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'

const PaperComponent = (props) => (
  <Draggable handle=".MuiDialogTitle-root, .MuiDialogContentText-root">
    <Paper {...props} />
  </Draggable>
)

const DraggableDialogTitle = styled(DialogTitle)`
  cursor: move;
  text-align: center;

  &:active {
    cursor: move;
  }
`

/*DendroDialog can be used as a drop-in replacement for a draggable version
of a material-ui Dialog. The only difference is that the title must be passed
as a prop to make it draggable. The rest, including use of DialogContent,
DialogContentText, etc. is up to the child component.*/

const DendroDialog = ({
  fullScreenAt,
  onClose,
  open,
  isDraggable,
  draggableTitle,
  ...otherProps
}) => {
  const theme = useTheme()

  const mediaQuery = useMediaQuery(theme.breakpoints.down(fullScreenAt || 'xs'))

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperComponent={PaperComponent}
      fullScreen={!!fullScreenAt ? mediaQuery : undefined}
      {...otherProps}
    >
      {!!isDraggable ? (
        <DraggableDialogTitle>{draggableTitle}</DraggableDialogTitle>
      ) : null}

      {otherProps.children}
    </Dialog>
  )
}

//Since title may include a close button, it is classed as `PropTypes.any`
DendroDialog.propTypes = {
  draggableTitle: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  fullScreenAt: PropTypes.string, //optional full screen breakpoint
  isDraggable: PropTypes.bool,
}

export default DendroDialog
